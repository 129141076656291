@font-face {
  font-family: Chess;
  src: url('/chess.woff2');
}
.history-content {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  border: 1px grey solid;
  border-radius: 3px;
  margin-top: 20px;
  min-height: 30px;
  /* grid-gap: 5px;
  gap:5px; */
  padding: 5px;
  box-sizing: border-box;
}

.history-move-wrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  font-family: Chess;
}

.history-move-number {
  margin-right: 2px;
}
.history-move-delimeter {
  /* margin-right: 2px; */
}
.history-move {
  margin-right: 5px;
  border-bottom: 1px solid white;
}
.history-move.pointer {
  color: rgb(2, 143, 143)
}

.history-move-wrapper.pointer ~ .history-move-wrapper {
  color: lightgrey;
}

.history-content.no-pointer  .history-move-wrapper{
  color: lightgrey;
}
.history-move:hover {
  border-bottom: 1px dashed currentColor;
} 